import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import SVGHelper from '../../../../helpers/SVGHelper';

interface IProps {
  eventDate: any;
  lastInGroup?: boolean;
}

const DateRow: React.FC<IProps> = (props) => {
  var evt = props.eventDate;
  var eventDate = moment.utc(evt.EventDateAsString);

  var eventDateFormatted = (
    <span>
      {eventDate.format('ddd Do MMM YY')}
      <span style={{ color: '#b2bbc3' }}> at </span> {eventDate.format('h:mma')}
    </span>
  );
  var eventDateSoldPercentage = ((evt.SoldSeatedTickets + evt.SoldGATickets) / (evt.AvailableSeatedTickets + evt.AvailableGATickets)) * 100.0;
  if (isNaN(eventDateSoldPercentage)) {
    eventDateSoldPercentage = 0;
  }

  var url = '/' + evt.EventTag + '/tickets/' + eventDate.format('DDMMMYYYY/HHmm');

  return (
    <tr className="event-date animated fadeIn" style={{ borderBottom: props.lastInGroup ? '8px solid #ddd' : null }}>
      <td>{<img style={{ display: evt.Ended ? 'block' : 'none' }} className="row-status" src={SVGHelper.get('Past')} />}</td>
      <td>
        <Link to={url}>{eventDateFormatted}</Link>
      </td>
      <td>
        {eventDateSoldPercentage.toFixed(1)}
        <span style={{ color: '#b2bbc3' }}>%</span>
      </td>
      <td style={{}}>
        {evt.SoldSeatedTickets + evt.SoldGATickets}
        <span style={{ color: '#b2bbc3' }}>/{evt.AvailableSeatedTickets + evt.AvailableGATickets}</span>
      </td>
      <td style={{ textAlign: 'right' }}>
        <span style={{ color: '#b2bbc3' }}>{evt.CurrencySymbol}</span>
        <span>{((evt.SoldSeatedTicketsValue + evt.SoldGATicketsValue) / 100.0).toFixed(2)}</span>
      </td>
    </tr>
  );
};

export default DateRow;
