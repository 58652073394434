import React from 'react';

import SVGHelper from '../helpers/SVGHelper';
import SVGInfo from '../svg/SVGInfo';
import { InfoType } from './BlockInfo';
import SVGTick from '../svg/SVGTick';
import SVGTickCircled from '../svg/SVGTickCircled';

export interface IProps {
  type?: InfoType;
}

const Info: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  var type = props.type ? props.type : InfoType.Info;
  return (
    <table className="table-info">
      <tbody>
        <tr>
          <td className="icon">
            <div>
              {type == InfoType.Success ? (
                <SVGTickCircled />
              ) : type == InfoType.Error ? (
                <img src={SVGHelper.get('Error')} />
              ) : type == InfoType.Warning ? (
                <img src={SVGHelper.get('Warning')} />
              ) : (
                <SVGInfo />
              )}
            </div>
          </td>
          <td className="text">{props.children}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Info;
