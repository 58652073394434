import linq from 'linq';
import React, { useState } from 'react';
import AdminApi from '../../../api/AdminApi';
import Block from '../../../components/Block';
import BlockHeader from '../../../components/BlockHeader';
import BlockInfo from '../../../components/BlockInfo';
import Button from '../../../components/Button';
import InfoInput from '../../../components/InfoInput';
import Modal from '../../../components/Modal';
import SpacerTable from '../../../components/SpacerTable';
import GuidHelper from '../../../helpers/GuidHelper';
import VenueHelper from '../../../helpers/VenueHelper';
import { IEvent } from '../../../interfaces/IEvent';
import { ISeatingPlan } from '../../../interfaces/ISeatingPlan';
import { IVenue } from '../../../interfaces/IVenue';
import SVGEditVenue from '../../../svg/SVGEditVenue';
import SVGSearch from '../../../svg/SVGSearch';
import Toolbar from '../toolbar/Toolbar';

export interface IProps {
  event: IEvent;
  onEventUpdated: (e: IEvent) => void;
  globalOptions: JSX.Element;
  setBusyMessage: (value: string) => void;
  hasOrders: boolean;
}

const VenueSection: React.FC<IProps> = (props) => {
  const { event, onEventUpdated, setBusyMessage } = props;
  const [selectedVenue, setSelectedVenue] = useState<IVenue>(null);

  const venue = event.Venue;

  const createNew = () => {
    onEventUpdated({ ...event, Venue: VenueHelper.getDefault() });
  };

  const [searchResults, setSearchResults] = useState<IVenue[]>(null);
  const findExisting = () => {
    setBusyMessage('Loading venues...');
    AdminApi.request('GET', '/api/AvailableVenues')
      .then((result: IVenue[]) => {
        setSearchResults(
          linq
            .from(result)
            .orderBy((venue) => venue.Name)
            .toArray(),
        );
        setBusyMessage(null);
      })
      .catch((message) => {
        alert(message);
        setBusyMessage(null);
      });
  };

  return (
    <>
      {searchResults && (
        <Modal onCloseClick={() => setSearchResults(null)}>
          <div className="content">
            <div className="ticket-rip" />

            {searchResults.length == 0 ? (
              <div className="body">
                <SpacerTable>
                  <h1>Sorry...</h1>
                  You have no other events with venues to copy! You will have to create a new one and fill in the details on the form.
                </SpacerTable>
              </div>
            ) : (
              <div className="body">
                {selectedVenue ? (
                  <>
                    <SpacerTable>
                      <h1>{selectedVenue.Name}</h1>
                      What would you like to load from this venue?
                    </SpacerTable>
                    <div className="spacer" />
                    <table className="blocks">
                      <tbody>
                        <Block
                          className="route"
                          onClick={() => {
                            onEventUpdated({ ...event, Venue: { ...selectedVenue } });
                            setSearchResults(null);
                            setSelectedVenue(null);
                          }}
                        >
                          <BlockHeader>Venue</BlockHeader>
                          We will load only the venue into this event, no map or seating plan will be loaded
                        </Block>
                        {/* TODO: Possibly re-add {selectedVenue.SeatingPlanId != null && selectedVenue.SeatingPlanId != 0 && (
                          <Block
                            className="route"
                            onClick={() => {
                              setBusyMessage('Loading venue details...');

                              loadSeatingPlan().then((seatingPlan: ISeatingPlan) => {
                                if (!seatingPlan) return;
                                onEventUpdated({ ...event, Venue: { ...selectedVenue }, UseSeatingPlan: true, SeatingPlan: { ...seatingPlan, Id: 0 }, AllocatedCategoryGroups: [] });
                                setSearchResults(null);
                                setSelectedVenue(null);
                                setBusyMessage(null);
                              });
                            }}
                          >
                            <BlockHeader>Venue & seating plan</BlockHeader>
                            The venue & seating plan will be loaded, tickets will be cleared
                          </Block>
                        )} */}
                        {selectedVenue.ShowMap && (
                          <Block
                            className="route"
                            onClick={() => {
                              onEventUpdated({ ...event, Venue: { ...selectedVenue }, Lat: selectedVenue.Lat, Lng: selectedVenue.Lng, ShowMap: true });
                              setSearchResults(null);
                              setSelectedVenue(null);
                            }}
                          >
                            <BlockHeader>Venue & map</BlockHeader>
                            We will load only the venue & the map into this event
                          </Block>
                        )}
                        {/* {selectedVenue.SeatingPlanId != null && selectedVenue.SeatingPlanId != 0 && selectedVenue.ShowMap && (
                          <Block
                            className="route"
                            onClick={() => {
                              setBusyMessage('Loading venue details...');

                              loadSeatingPlan().then((seatingPlan: ISeatingPlan) => {
                                onEventUpdated({
                                  ...event,
                                  Venue: { ...selectedVenue },
                                  UseSeatingPlan: true,
                                  SeatingPlans: [{ ...seatingPlan, Id: 0 }],
                                  Lat: selectedVenue.Lat,
                                  Lng: selectedVenue.Lng,
                                  ShowMap: true,
                                  AllocatedCategoryGroups: [],
                                });
                                setSearchResults(null);
                                setSelectedVenue(null);
                                setBusyMessage(null);
                              });
                            }}
                          >
                            <BlockHeader>Venue, seating plan & map</BlockHeader>
                            We will load the venue, seating plan and map details into this event, tickets will be cleared
                          </Block>
                        )} */}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    <SpacerTable>
                      <h1>Find Existing Venue</h1>
                      Select a venue from the options below to load it into this event. Changes to a shared venue in one event will take affect accross events.
                    </SpacerTable>
                    <div className="spacer" />
                    <table className="blocks">
                      <tbody>
                        {props.hasOrders && <BlockInfo>This event has orders against it so loading existing seating plans & maps has been disabled.</BlockInfo>}
                        {searchResults.map((result) => (
                          <Block
                            className="route"
                            onClick={() => {
                              if (!props.hasOrders && (result.SeatingPlanId != null || result.ShowMap)) {
                                setSelectedVenue(result);
                              } else {
                                onEventUpdated({ ...event, Venue: { ...result } });
                                setSearchResults(null);
                              }
                            }}
                            key={'result_' + result.Id}
                          >
                            <BlockHeader>{result.Name}</BlockHeader>
                            <div>
                              {result.City}
                              {result.City && result.City.length > 0 && ', '}
                              {result.Postcode}
                            </div>
                          </Block>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}

                <div className="spacer-x2" />

                <SpacerTable>
                  <Button className="cancel" onExecute={() => setSearchResults(null)} text="Cancel" />
                </SpacerTable>
              </div>
            )}

            <div className="ticket-rip bottom" />
          </div>
        </Modal>
      )}
      <Toolbar>
        <div className="options">
          {props.globalOptions}
          {venue.Id != null && venue.Id > 0 && (
            <button className="option" onClick={createNew}>
              <label>Create New</label>
              <div className="icon">
                <SVGEditVenue />
              </div>
            </button>
          )}
          <button className="option" onClick={findExisting}>
            <label>Find Existing</label>
            <div className="icon">
              <SVGSearch />
            </div>
          </button>
        </div>
      </Toolbar>
      <div className="row">
        <div className="col-sm-8">
          <div className="fields">
            <h2>Venue details</h2>
            <div
              className="section"
              style={{
                marginTop: '0',
              }}
            >
              <label className="label">Where will your event take place?</label>

              <div className="row smallmargins">
                <div className="col-sm-8">
                  <InfoInput labelText="Name" maxLength={100} onChange={(value) => onEventUpdated({ ...event, Venue: { ...venue, Name: value } })} value={venue.Name} />
                  <InfoInput labelText="Street address" maxLength={100} onChange={(value) => onEventUpdated({ ...event, Venue: { ...venue, StreetAddress: value } })} value={venue.StreetAddress} />
                </div>
                <div className="col-sm-8">
                  <InfoInput labelText="City" maxLength={100} onChange={(value) => onEventUpdated({ ...event, Venue: { ...venue, City: value } })} value={venue.City} />
                </div>
                <div className="col-sm-4 col-xs-6">
                  <InfoInput labelText="Postcode" maxLength={100} onChange={(value) => onEventUpdated({ ...event, Venue: { ...venue, Postcode: value } })} value={venue.Postcode} />
                </div>
              </div>
            </div>
          </div>

          <div className="fields">
            <h2>About your venue</h2>
            <div
              className="section"
              style={{
                marginTop: '0',
              }}
            >
              <label className="label">How would you describe the venue?</label>

              <InfoInput maxLength={1000} rows={12} labelText="Description" onChange={(value) => onEventUpdated({ ...event, Venue: { ...venue, Description: value } })} value={venue.Description} />
            </div>
          </div>

          <div className="fields">
            <h2>Directions</h2>
            <div className="section" style={{ marginTop: '0' }}>
              <label className="label">Do you have any specific directions?</label>

              <InfoInput maxLength={1000} rows={8} labelText="Directions" onChange={(value) => onEventUpdated({ ...event, Venue: { ...venue, Directions: value } })} value={venue.Directions} />
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="info">
            You can <strong>Create a New</strong> venue for this event or <strong>Find an Existing</strong> one. If you edit an event that you loaded from the find existing feature, changes will take
            effect accross all events that use this venue.
          </div>
        </div>
      </div>
    </>
  );
};

export default VenueSection;
